import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { isPWA } from '@/utils/is-pwa';

const environment = import.meta.env.DEPLOYMENT_ENVIRONMENT;
const dsn = import.meta.env.SENTRY_DSN_FE;
const appVersion = import.meta.env.APP_VERSION;

Sentry.init({
  dsn,
  environment,
  release: appVersion,
  maxValueLength: 200,
  beforeBreadcrumb(breadcrumb, hint) {
    if (
      breadcrumb.category === 'fetch' &&
      typeof breadcrumb.data?.url === 'string' &&
      breadcrumb.data.url.includes(';base64,') &&
      breadcrumb.data.url.length > 200
    ) {
      // Sentry adds breadcrumbs and some of them are fetch requests against our base64 photos (when they are converted to blobs)
      // As these URLs are huge, error envelope reaches its limit and is not sent to Sentry
      // The long URL looks like this: `data:image/jpeg;base64,/9j/4AAUSkZJRgABAQEBLAEsAAB...`
      // eslint-disable-next-line no-param-reassign
      breadcrumb.data.url = breadcrumb.data.url.slice(0, 200);
    }

    // Add POST body to breadcrumbs. Useful for GraphQL query debugging.
    if (
      breadcrumb.category === 'fetch' &&
      breadcrumb.data?.method === 'POST' &&
      !breadcrumb.data?.body
    ) {
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#hints-for-events
      // `fetch` breadcrumb hint equals `fetch` call params
      //    but we are able to transform only textual variant
      //    as fetch `ReadableStream`s are readable only once
      const input: Parameters<typeof window.fetch> | undefined = hint?.input;
      if (input?.length === 2) {
        const bodyString = input[1]?.body;
        if (typeof bodyString === 'string' || bodyString instanceof String) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.body = bodyString
            .slice(0, 1000)
            .replaceAll('\\n', '\n');
        }
      }
    }

    return breadcrumb;
  },

  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
  ],
  tracePropagationTargets: [import.meta.env.BACKEND_URL],
  tracesSampleRate: import.meta.env.DEV ? 1 : 0.1,
  profilesSampleRate: import.meta.env.DEV ? 1 : 0.1,
});

if (import.meta.env.DEV) {
  if (!import.meta.env.DISABLE_SPOTLIGHT) {
    const Spotlight = await import('@spotlightjs/spotlight');

    Spotlight.init({
      showTriggerButton: false,
      sidecarUrl: import.meta.env.SENTRY_SPOTLIGHT,
    });
  }
}

Sentry.setUser({
  ip_address: '{{auto}}',
});

if (isPWA()) Sentry.setTag('PWA', 'true');
