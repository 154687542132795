import { createPathGenerator } from './utils';

export const pathToOfficeAppointments = createPathGenerator('/appointments');
export const pathToOfficeAppointmentsNew =
  pathToOfficeAppointments.createChildPath('new');
export const pathToOfficeAppointmentsEdit =
  pathToOfficeAppointments.createChildPath('edit/:id');
export const pathToOfficeAppointmentsSuggested =
  pathToOfficeAppointments.createChildPath('suggested/:id');

export const pathToOfficeAppointment = createPathGenerator(
  '/office/appointments/:appointmentId',
);
export const pathToOfficeAppointmentReports =
  pathToOfficeAppointment.createChildPath('reports');
export const pathToOfficeAppointmentReport =
  pathToOfficeAppointmentReports.createChildPath(':reportId');
export const pathToOfficeAppointmentReportPreview =
  pathToOfficeAppointmentReport.createChildPath('preview');
export const pathToOfficeAppointmentReportEdit =
  pathToOfficeAppointmentReport.createChildPath('edit');
export const pathToOfficeAppointmentReportEmailComposer =
  pathToOfficeAppointmentReport.createChildPath('send');

export const pathToOfficeCustomers = createPathGenerator('/customers');
export const pathToOfficeCustomersNew =
  pathToOfficeCustomers.createChildPath('new');
export const pathToOfficeCustomersEdit =
  pathToOfficeCustomers.createChildPath('edit/:id');

export const pathToOfficeLocations = createPathGenerator('/locations');
export const pathToOfficeLocationLinkToTce =
  pathToOfficeLocations.createChildPath('link-to-tce/:id');

export const pathToOfficeLocation =
  pathToOfficeLocations.createChildPath(':locationId');
export const pathToOfficeLocationReports =
  pathToOfficeLocation.createChildPath('reports');
export const pathToOfficeLocationAttachments =
  pathToOfficeLocation.createChildPath('attachments');
export const pathToOfficeLocationAppointments =
  pathToOfficeLocation.createChildPath('appointments');
export const pathToOfficeLocationSystems =
  pathToOfficeLocation.createChildPath('systems');
